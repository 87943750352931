import React from "react";
import { Link as PrismicLink } from "prismic-reactjs";
import { Elements } from "prismic-richtext";
import { Link } from "gatsby";
import linkResolver from "./linkResolver";
import { H1, H2, H3, H4, H5, H6, P } from "../components/typography";

const htmlSerializer = (
  type: any,
  element: any,
  content: any,
  children: any,
  index: any
) => {
  // Generate links to Prismic Documents as <Link> components
  if (type === Elements.hyperlink) {
    let result = <></>;
    const url = PrismicLink.url(element.data, linkResolver);

    if (element.data.link_type === "Document") {
      result = (
        <Link to={url} key={index}>
          {content}
        </Link>
      );
    } else {
      const target = element.data.target && {
        target: element.data.target,
        rel: "noopener",
      };
      result = (
        <a href={url} target={target.target} rel={target.rel} key={index}>
          {content}
        </a>
      );
    }
    return result;
  }

  if (type === Elements.paragraph) {
    return (
      <P className="text-base text-gray-500 sm:text-lg md:text-xl">
        {children.join("")}
      </P>
    );
  }

  if (type === Elements.heading1)
    return (
      <H1 colorClass="text-gray-700" className="md:text-6xl">
        {children.join("")}
      </H1>
    );
  if (type === Elements.heading2)
    return (
      <H2 colorClass="text-gray-700" className="md:text-5xl">
        {children.join("")}
      </H2>
    );
  if (type === Elements.heading3)
    return (
      <H3 colorClass="text-gray-700" className="md:text-4xl">
        {children.join("")}
      </H3>
    );
  if (type === Elements.heading4)
    return (
      <H4 colorClass="text-gray-700" className="md:text-3xl">
        {children.join("")}
      </H4>
    );
  if (type === Elements.heading5)
    return (
      <H5 colorClass="text-gray-700" className="md:text-2xl">
        {children.join("")}
      </H5>
    );
  if (type === Elements.heading6)
    return (
      <H6 colorClass="text-gray-700" className="md:text-xl">
        {children.join("")}
      </H6>
    );

  // If the image is also a link to a Prismic Document, it will return a <Link> component
  if (type === Elements.image) {
    let result = <img src={element.url} alt={element.alt || ""} />;

    if (element.linkTo) {
      const url = PrismicLink.url(element.linkTo, linkResolver);

      if (element.linkTo.link_type === "Document") {
        result = (
          <Link to={url} key={index}>
            {result}
          </Link>
        );
      } else {
        const target = element.linkTo.target && {
          target: element.linkTo.target,
          rel: "noopener",
        };
        result = (
          <a href={url} target={target}>
            {result}
          </a>
        );
      }
    }
    const wrapperClassList = [element.label || "", "block-img"];
    result = (
      <P className={wrapperClassList.join(" ")} key={index}>
        {result}
      </P>
    );
    return result;
  }

  // Return null to stick with the default behavior for everything else
  return null;
};

export default htmlSerializer;
