import React from "react";
import { RichText } from "prismic-reactjs";
import GatsbyLink from "../GatsbyLink";
import htmlSerializer from "../../../utils/htmlSerializer";

export default ({ slice }: any) => (
  <div className="mt-6 mx-auto">
    <RichText
      render={slice.primary.text.raw || []}
      serializeHyperlink={GatsbyLink}
      htmlSerializer={htmlSerializer}
    />
  </div>
);
