/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from "react";
import { graphql, Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ImageCaption, Quote, Text } from "../components/blog_post/slices";
import { H1, P } from "../components/typography";
import {
  ArrowLeftIcon
} from "@heroicons/react/outline";

// Query for the Blog Post content in Prismic
export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismicBlogPost(uid: { eq: $uid }){
      id
      uid
      lang
      last_publication_date(formatString: "DD/MM/Y")
      data {
        description {
          text
        }
        authors_name {
          text
        }
        body {
          ... on PrismicBlogPostDataBodyImageWithCaption {
            id
            slice_label
            slice_type
            primary {
              caption {
                raw
              }
              image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicBlogPostDataBodyQuote {
            id
            slice_label
            slice_type
            primary {
              quote {
                raw
              }
            }
          }
          ... on PrismicBlogPostDataBodyText {
            id
            slice_label
            slice_type
            primary {
              text {
                raw
              }
            }
          }
        }
        hero_image {
          gatsbyImageData(
            aspectRatio: 2.28571428571
            layout: FULL_WIDTH
            sizes: "(min-width: 2560px) 2560px, 75vw"
          )
          alt
        }
        title {
          raw
        }
      }
    }
  }
`;

// Sort and display the different slice options
const PostSlices = ({ slices }: any) =>
  slices.map((slice: any, index: any) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "text":
          return <Text key={index} slice={slice} />;

        case "quote":
          return <Quote key={index} slice={slice} />;

        case "image_with_caption":
          return <ImageCaption key={index} slice={slice} />;

        default:
          return <></>;
      }
    })();
    return res;
  });

// Display the title, date, and content of the Post
const PostBody = ({ blogPost }: any) => { 
  const image = getImage(blogPost.data.hero_image);
  
  return(
  <>
    <div className="hidden lg:block lg:absolute lg:inset-y-64 lg:h-full lg:w-full">
      <div
        className="relative h-full text-lg max-w-prose mx-auto"
        aria-hidden="true"
      >
        <svg
          className="absolute top-12 left-full transform translate-x-32"
          width={404}
          height={384}
          fill="none"
          viewBox="0 0 404 384"
        >
          <defs>
            <pattern
              id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={384}
            fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
          />
        </svg>
        <svg
          className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
          width={404}
          height={384}
          fill="none"
          viewBox="0 0 404 384"
        >
          <defs>
            <pattern
              id="f210dbf6-a58d-4871-961e-36d5016a0f49"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={384}
            fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
          />
        </svg>
        <svg
          className="absolute bottom-12 left-full transform translate-x-32"
          width={404}
          height={384}
          fill="none"
          viewBox="0 0 404 384"
        >
          <defs>
            <pattern
              id="d3eb07ae-5182-43e6-857d-35c643af9034"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={384}
            fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
          />
        </svg>
      </div>
    </div>
    <header className="flex flex-col">
      <div className="flex flex-col md:flex-row pl-6 sm:pl-20 md:pl-28 pt-12 pb-12 md:pt-24 md:pb-20">
        <Link to="/learn/">
          <ArrowLeftIcon
            className="h-6 w-6 md:h-10 md:w-10"
            aria-label="Back button"
          />
        </Link>
        <div className="md:pl-12 lg:pl-24"> 
          <H1 className="md:pb-4 pb-2 font-semibold md:text-6xl text-4xl">
            {RichText.asText(blogPost.data.title.raw).length !== 0
              ? RichText.asText(blogPost.data.title.raw)
              : "Untitled"}
          </H1>
          <div className="flex">
            <P className="md:text-2xl text-xl text-tight font-semibold">
              {blogPost.data?.authors_name.text}
            </P>
            <P className="md:text-xl text-tight pl-1 pt-1 self-center" colorClass="text-gray-500" >
            ·{blogPost.last_publication_date}
            </P>
          </div>
        </div>
      </div>
      <div className="bg-blue-600 z-10">
      {image && <GatsbyImage className="w-full mx-auto object-cover max-h-108" image={image} alt={blogPost.data.hero_image.alt} /> }
      </div>
    </header>
    {/* Go through the slices of the post and render the appropriate one */}
    <div className="relative max-w-4xl mx-auto bg-white mt-12 px-6 sm:px-12">
      <PostSlices slices={blogPost.data.body} />
    </div>
  </>
); };

export const Post = ({ data }: any) => {
  if (data === undefined) return null;
  // Define the Post content returned from Prismic
  const post = data.prismicBlogPost;
  return (
    <Layout
      title={post.data.title.raw.text}
      canonical={`learn/${post.uid}`}
      description={post.data.description && post.data.description.text}
      siteName="Vessel Learn"
      image={post.data.hero_image.url}
      twitterUsername={
        post.data.authors_twitter && post.data.authors_twitter.text
      }
      article
    >
      <PostBody blogPost={post} />
    </Layout>
  );
};

export default Post;
