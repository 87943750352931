// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

const linkResolver = (doc: any) => {
  if (doc.type === "blog_post") {
    return `/learn/${doc.uid}`;
  }
  return "/";
};

export default linkResolver;
